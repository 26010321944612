<template>
  <div>
    <!-- select 2 demo -->

    <div>
      <!-- Table Top -->

      <div>
        <b-sidebar
          id="sidebar-right"
          ref="mySidebar"
          bg-variant="white"
          v-model="visibility"
          sidebar-class="sidebar-lg"
          right
          no-header
          backdrop
          shadow
        >
          <div
            class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
          >
            <h5 class="mb-0">Publish Datesheet</h5>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hideSideBar()"
            />
          </div>
          <b-form class="p-2" @submit.prevent>
            <b-row>
              <b-col md="12">
                <b-form-group
                  label="Publish From"
                  invalid-feedback="Date is required."
                  ref="pub_from"
                >
                  <flat-pickr
                    :config="config"
                    ref="pub_from"
                    v-model="myObj.publishedFrom"
                    class="form-control"
                    placeholder="Select Date."
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Publish Till"
                  invalid-feedback="Date is required."
                  ref="pub_till"
                >
                  <flat-pickr
                    :config="config"
                    ref="pub_till"
                    v-model="myObj.publishedTo"
                    class="form-control"
                    placeholder="Select Date."
                  />
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  @click="Add()"
                  :disabled="publishing"
                  block
                >
                  <b-spinner v-if="publishing" small type="grow" />
                  <span v-else>Publish</span>
                </b-button>
              </b-col>
              <b-col md="12" class="mt-1" v-if="showBtn">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  @click="unPub()"
                  :disabled="unpublishing"
                  block
                >
                  <b-spinner v-if="unpublishing" small type="grow" />
                  <span v-else>Unpublish</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
          <sidebar-content />
        </b-sidebar>

        <b-card>
          <b-row class="mt-1">
            <b-col xl="2" lg="3" md="4" sm="12" cols="12" v-if="rights.add">
              <b-button
                @click="open(0)"
                style="width: 100%"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
              >
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle">Datesheet</span>
              </b-button>
            </b-col>

            <b-col
              class="mt-lg-0 mt-md-0 mt-sm-1 mt-1 d-flex align-items-center"
              xl="10"
              lg="9"
              md="8"
              sm="12"
              cols="12"
            >
              <b-form-group class="mb-0 w-100">
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>

                  <b-form-input v-model="searchQuery" placeholder="Search...">
                  </b-form-input>
                </b-input-group>
              </b-form-group>
              <b-button
                variant="outline-primary"
                v-b-tooltip.hover.top
                title="Export"
                class="btn-icon ml-50"
                :disabled="exporting"
                @click="exportData()"
              >
                <b-spinner v-if="exporting" small varaint="primary" />
                <feather-icon v-else icon="UploadIcon" class="" />
              </b-button>
            </b-col>
          </b-row>

          <b-table
            class="mt-1"
            ref="selectableTable"
            :items="filters"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :busy="dataLoading"
            show-empty
            responsive
            hover
            :selectable="rights.edit"
            select-mode="single"
            @row-selected="open($event[0].datesheetGrid.id, $event[0])"
          >
            <template #table-busy>
              <div
                class="d-flex justify-content-center mb-3"
                style="margin-top: 50px"
              >
                <b-spinner
                  style="width: 3rem; height: 3rem"
                  type="grow"
                  variant="primary"
                  label="Spinning"
                ></b-spinner>
              </div>
            </template>
            <template #cell(metaGrid.cls)="data">
              <b-badge variant="light-primary">
                {{ data.value }}
              </b-badge>
            </template>
            <template #cell(metaGrid.sec)="data">
              <b-badge variant="light-primary">
                {{ data.value }}
              </b-badge>
            </template>
            <template #cell(metaGrid.exam)="data">
              <b-badge variant="light-primary">
                {{ data.value }}
              </b-badge>
            </template>
            <template #cell(metaGrid.session)="data">
              <b-badge variant="light-primary">
                {{ data.value }}
              </b-badge>
            </template>

            <template #head(actions)="data">
              <div class="ml-2">
                <span>{{ data.label }}</span>
              </div>
            </template>

            <template #cell(actions)="data">
              <div class="">
                <b-button
                  v-if="rights.edit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  v-b-tooltip.hover.left
                  title="Edit"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="open(data.item.datesheetGrid.id, data.item)"
                >
                  <feather-icon icon="EditIcon" class="" />
                </b-button>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  v-b-tooltip.hover.left
                  title="Print"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="Print(data.item)"
                >
                  <feather-icon icon="PrinterIcon" class="" />
                </b-button>

                <!-- v-if="!data.item.datesheetGrid.isPublished" -->
                <b-button
                  v-if="rights.edit"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  v-b-tooltip.hover.right
                  title="App Setting"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="AddOpen(data.item, true)"
                >
                  <feather-icon icon="GlobeIcon" />
                </b-button>

                <b-button
                  v-if="rights.delete"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  v-b-tooltip.hover.right
                  title="Delete"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="Delete(data.item.datesheetGrid.id)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
                <!-- <b-button
                  v-if="data.item.datesheetGrid.isPublished"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  v-b-tooltip.hover.right
                  title="Unpublish"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="unPub(data.item.datesheetGrid)"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
                <b-button
                  v-if="data.item.datesheetGrid.isPublished"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  v-b-tooltip.hover.right
                  title="Edit Publish"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="AddOpen(data.item.datesheetGrid, false)"
                >
                  <feather-icon icon="Edit2Icon" /> 
                </b-button> -->
              </div>
            </template>
          </b-table>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="filters.length"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BFormTextarea,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { forEach } from "postcss-rtl/lib/affected-props";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapGetters } from "vuex";

import * as XLSX from "xlsx";

export default {
  components: {
    flatPickr,
    BFormTextarea,
    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    // BFormCheckbox,
    BForm,
    BPagination,
    BSpinner,

    BDropdown,
    BDropdownItem,

    vSelect,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    // console.log(this.$store.state.rights);
    let right = {};
    this.$store.state.menu.forEach((el) => {
      el.children.forEach((ch) => {
        right[ch.route] = {
          view: ch.view,
          add: ch.add,
          edit: ch.edit,
          delete: ch.delete,
        };
      });
    });
    this.$store.commit("setRights", right);
    if (!this.$store.state.rights[this.$route.name]) {
      // console.log(this.$store.state.rights[this.$route.name]);
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];
      this.LoadData();
    }
    // this.LoadData();
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),
    getColor(ind) {
      console.log("%%%%%% getting color", ind.index);
      return this.color[ind];
    },

    PageChange() {
      console.log(this.currentPage);
      return this.LoadData();
    },
    filters: function () {
      return this.items.filter((pro) => {
        return (
          pro.metaGrid.cls
            .toLowerCase()
            .match(this.searchQuery.toLowerCase()) ||
          pro.metaGrid.sec.toLowerCase().match(this.searchQuery.toLowerCase())
        );
      });
    },
  },
  data() {
    return {
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      rights: {},
      myDepartments: [],
      request: false,
      colorDep: [],
      visibility1: false,
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      color: [
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
      ],

      sidebarTitle: "Add Subject",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,

      visibility: false,
      publishing: false,
      unpublishing: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      totalRows: 0,

      errors: {
        status: false,
      },

      index: null,

      fields: [
        { label: "class", key: "metaGrid.cls" },
        { label: "section", key: "metaGrid.sec" },
        { label: "exam", key: "metaGrid.exam" },
        { label: "session", key: "metaGrid.session" },
        // { label: "syllabus", key: "syllabus" },
        { key: "actions", label: "actions" },
      ],
      items: [],
      selected: null,
      rangeDate: null,
      adding: false,
      showBtn: false,
      myObj: {},
      depObj: {
        id: 0,
        name: "",
        campusID: this.$store.state.userData.cId,
      },
      detailObj: {},
      timeout: 10000,
      exporting: false,
      pubObj: {},
    };
  },
  validations: {
    depObj: {
      name: {
        required,
        minLength: minLength(4),
      },
    },
    age: {
      between: between(20, 30),
    },
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    exportData() {
      this.exporting = true;

      const newData = this.items.map((el) => ({
        class: el.metaGrid.cls,
        section: el.metaGrid.sec,
        exam: el.metaGrid.exam,
        session: el.metaGrid.session,
        publish_from: el.datesheetGrid.publishedFrom,
        publish_till: el.datesheetGrid.publishedTo,
      }));

      const worksheet = XLSX.utils.json_to_sheet(newData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
      XLSX.writeFile(workbook, "datesheet.xlsx");

      this.exporting = false;
    },

    Print(item) {
      // console.log(item);

      var url =
        "https://" +
        this.reportDomain +
        ".myskool.app/Exams/ShowDatesheet?dbb=" +
        this.$store.state.userData.db +
        "&cID=" +
        this.$store.state.userData.cId +
        "&gridID=" +
        item.datesheetGrid.id;

      window.open(url, "_blank");
    },
    open(id, item) {
      this.$router.push({
        name: "datesheet",
        params: {
          id: id,
          obj: item,
        },
      });
    },

    async Edit(id) {
      var obj = {
        url:
          this.$store.state.domain +
          "Subjects/GetSelected?id=" +
          id +
          "&db=" +
          this.$store.state.userData.db,
        token: this.$store.state.userData.token,
      };
      this.myObj = await this.get(obj);
      // console.log("editObj:", this.myObj);
      this.adding = false;
      this.visibility = true;
      this.sidebarTitle = "Edit Subject";
      this.sidebarButton = "Update";
      var elem = this.$refs["subject"];
      elem.state = undefined;
    },

    AddOpen(item, st) {
      this.myObj = { ...item.datesheetGrid };
      this.pubObj = { ...item };
      // console.log(this.myObj, this.pubObj);
      if (this.myObj.isPublished) this.showBtn = true;
      else this.showBtn = false;
      this.adding = st;
      this.visibility = true;

      var elem = this.$refs["pub_from"];
      elem.state = undefined;
      var elem = this.$refs["pub_till"];
      elem.state = undefined;
    },

    checkPubFrom() {
      var elem = this.$refs["pub_from"];
      if (!this.myObj.publishedFrom) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkPubTill() {
      var elem = this.$refs["pub_till"];
      if (!this.myObj.publishedTo) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },

    hideSideBar() {
      this.visibility = false;
    },

    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "DatesheetGrid/Load?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);

      this.dataLoading = false;
      // console.log("subject", this.items);
    },

    async Add() {
      this.checkPubFrom();
      this.checkPubTill();
      if (this.checkPubFrom() == true && this.checkPubTill() == true) {
        this.publishing = true;
        this.myObj.isPublished = true;
        // let msg = "Datesheet published successfully!";
        // if (!this.adding) msg = "Publish details updated!";

        var status = await this.post({
          url:
            this.$store.state.domain +
            "DatesheetGrid/Publish?db=" +
            this.$store.state.userData.db +
            "&userID=" +
            this.$store.state.userData.userID,
          body: {
            metaGrid: this.pubObj.metaGrid,
            datesheetGrid: this.myObj,
            datesheetData: this.pubObj.datesheetData,
          },
          message: "Datesheet published successfully!",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.publishing = false;
        if (status) {
          this.LoadData();
          this.visibility = false;
        }
      }
    },

    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });
      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "DatesheetGrid/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          message: "Datesheet removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadData();
      }
    },
    async unPub() {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "Dou you want to unpublish this datesheet?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes",
      });
      if (result.isConfirmed) {
        this.unpublishing = true;
        this.myObj.isPublished = false;
        var status = await this.put({
          url:
            this.$store.state.domain +
            "DatesheetGrid/" +
            this.myObj.id +
            "?db=" +
            this.$store.state.userData.db,
          body: this.myObj,
          message: "Datesheet unpublished!",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.unpublishing = false;
        if (status) {
          this.LoadData();
          this.visibility = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
